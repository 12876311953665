import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../component/api/axiosApi";
import { DangerRight, Success } from "../../component/api/toastServices";

const initialState = {
  gift: [],
  isLoading: false,
  isSkeleton: false,
};

export const getAllGift = createAsyncThunk("gift/all", async () => {
  return apiInstanceFetch.get("gift/all");
});

export const creategift = createAsyncThunk("gift", async (payload) => {
  return apiInstance.post("gift", payload);
});

export const createSVGAGift = createAsyncThunk(
  "gift/svgaAdd",
  async (payload) => {
    return apiInstance.post("gift/svgaAdd", payload);
  }
);

export const deletegift = createAsyncThunk("gift/delete", async (id) => {
  return apiInstance.delete(`gift/delete?giftId=${id}`);
});

const giftSlice = createSlice({
  name: "giftSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllGift.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getAllGift.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.gift = action.payload.gift;
    });

    builder.addCase(getAllGift.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(creategift.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(creategift.fulfilled, (state, action) => {
      console.log("action.payload.gift", action.payload.gift);
      if (action.payload.status) {
        state.gift = action.payload.gift;

        Success("gift Add Successfully");
      }
      state.isLoading = false;
    });

    builder.addCase(creategift.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createSVGAGift.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(createSVGAGift.fulfilled, (state, action) => {
      console.log("action.payload.gift", action.payload.gift);
      if (action.payload.status) {
        state.gift = action.payload.data;

        Success("gift Add Successfully");
      }
      state.isLoading = false;
    });

    builder.addCase(createSVGAGift.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deletegift.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deletegift.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.gift = state.gift?.map((data) => ({
          ...data,
          gift: data?.gift?.filter((item) => item?._id !== action?.meta?.arg),
        }));
        Success("gift Delete Successfully");
      }
      state.isLoading = false;
    });
    builder.addCase(deletegift.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default giftSlice.reducer;
