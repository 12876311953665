import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slice/authSlice";
import dialogueSlice from "./slice/dialogueSlice";
import spinnerReducer from "./slice/loading.reducer";
import giftSlice from "./slice/giftSlice";
import categorySlice from "./slice/categorySlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    dialogue: dialogueSlice,
    gift: giftSlice,
    spinner: spinnerReducer,
    category: categorySlice,
  },
  devTools: false,
});

export default store;
