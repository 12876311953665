import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategory } from "../../../redux/slice/categorySlice";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import SVGA from "svgaplayerweb";
import Button from "../../extras/Button";
import html2canvas from "html2canvas";
import { createSVGAGift } from "../../../redux/slice/giftSlice";
const SvgaDialogue = () => {
  const dispatch = useDispatch();
  const { category } = useSelector((state) => state.category);
  const [categoryData, setCategoryData] = useState();
  const [images, setImages] = useState([]);

  const [imageData, setImageData] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const [mongoId, setMongoId] = useState("");
  const [coin, setCoin] = useState(0);
  const [isSvga, setIsSvga] = useState(false);
  const [image, setImage] = useState();
  const [cropper, setCropper] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const imageRef = useRef(null);

  const [errors, setError] = useState({
    image: "",
    category: "",
  });

  useEffect(() => {
    dispatch(getAllCategory());
  }, [dispatch]);

  useEffect(() => {
    setIsSubmit(false);
  }, []);

  const removeImage = () => {
    setImage("");
    setImageData(null);
    setImagePath(null);
    setIsSvga(false);
  };

  const handleInputImage = (e) => {
    setImage("");
    if (e.target.files[0]) {
      Object.assign(e.target.files[0], {
        preview: URL.createObjectURL(e.target.files[0]),
      });
      setImageData(e.target.files[0]);
      setImages([e.target.files[0]]);
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        setImagePath(reader.result);
        setError({
          image: "",
        });
      });
      reader.readAsDataURL(e.target.files[0]);

      if (e.target.files[0].name.split(".").pop() === "svga") {
        setIsSvga(true);
      } else {
        setIsSvga(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (!category) {
      const errors = {};

      if (!category) {
        errors.category = "Category can't be a blank!";
      }
      if (images.length === 0) {
        errors.image = "Please select an Image!";
      }

      return setError({ ...errors });
    }

    if (mongoId) {
      if (!imageData && !imagePath) {
        return setError({ ...errors, image: "Please select an Image!" });
      }
    } else {
      if (images.length === 0) {
        return setError({ ...errors, image: "Please select an Image!" });
      }
    }

    formData.append("svgaImage", image);

    formData.append("category", categoryData);

    for (let i = 0; i < images.length; i++) {
      formData.append("image", images[i]);
    }

    dispatch(createSVGAGift(formData));
    dispatch(closeDialog());
  };
  const onChange = (cropperRef) => {
    setCropper(cropperRef);
  };

  useEffect(() => {
    // if (imageData || dialogData) {
    if (isSvga) {
      if (!!document.getElementById("svga") && imagePath) {
        var player = new SVGA.Player(`div[attr="${mongoId}"]`);
        var parser = new SVGA.Parser(`div[attr="${mongoId}"]`);
        if (imageData?.preview) {
          parser.load(imageData.preview, function (videoItem) {
            player.setVideoItem(videoItem);
            player.startAnimation();
            setTimeout(() => {
              captureAndSendImage(player, mongoId);
            }, 3000);
          });
        }
      }
    }
  }, [imageData, isSvga, imagePath]);

  const captureAndSendImage = (player, index) => {
    return new Promise((resolve) => {
      player.pauseAnimation();
      const canvasElement = document.querySelector(
        `div[attr="${index}"] canvas`
      );

      const container = document.querySelector(`div[attr="${index}"]`);
      const canvas = document.createElement("canvas");

      // Set the desired width and height for the canvas
      const width = container.offsetWidth;
      const height = container.offsetHeight;

      canvas.width = width;
      canvas.height = height;

      if (!canvasElement) {
        return;
      }

      html2canvas(canvasElement, {
        scale: 1,
        useCORS: true,
        backgroundColor: "rgba(0, 0, 0, 0)",
        onclone: (cloneDoc) => {
          const clonedCanvas = cloneDoc.querySelector(
            `div[attr="${index}"] canvas`
          );
          clonedCanvas.style.backgroundColor = "transparent";
        },
      }).then((canvas) => {
        const data = canvas.toDataURL("image/png");
        canvas.toBlob((blob) => {
          resolve(blob);
          setImage(blob);
          setIsSubmit(true);
        }, "image/png");
      });
    });
  };

  return (
    <>
      <div
        className="dialog"
        style={{ overflowY: "hidden", overflowX: "hidden" }}
      >
        <div class="w-100">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-md-8 col-11">
              <div class="mainDiaogBox">
                <div class="row justify-content-between align-items-center formHead">
                  <div className="col-8">
                    <h2 className="text-theme m0">Gift Dialogue</h2>
                  </div>
                  <div className="col-4">
                    <div
                      className="closeButton"
                      onClick={() => {
                        dispatch(closeDialog());
                      }}
                    >
                      <i className="ri-close-line"></i>
                    </div>
                  </div>
                </div>
                <form id="expertForm">
                  <div
                    className="row align-items-start formBody"
                    style={{ overflowY: "hidden", overflowX: "hidden" }}
                  >
                    <div className="col-12 ">
                      <div className="inputData text  flex-row justify-content-start text-start">
                        <label for="category" class="false undefined">
                          Select Category
                        </label>
                        <select
                          class="form-select form-control"
                          aria-label="Default select example"
                          value={categoryData}
                          onChange={(e) => {
                            setCategoryData(e.target.value);
                            if (e.target.value === "Select Category") {
                              return setError({
                                ...errors,
                                category: "Please select a Category!",
                              });
                            } else {
                              return setError({
                                ...errors,
                                category: "",
                              });
                            }
                          }}
                        >
                          <option value="Select Category" selected>
                            Select Category
                          </option>
                          {category &&
                            category?.map((category) => {
                              return (
                                <option value={category?._id}>
                                  {category?.name}
                                </option>
                              );
                            })}
                        </select>
                        {errors.category && (
                          <div className="ml-2 mt-1">
                            {errors.category && (
                              <div className="pl-1 text__left">
                                <span className="text-red">
                                  {errors.category}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div class="form-group text-start">
                        <label className="mb-2 mt-2 text-gray">Icon</label>
                        {/* {!mongoId && (
                      <>
                        <input
                          class="form-control"
                          type="file"
                          required=""
                          onChange={handleInputImage}
                        />
                        {errors.image && (
                          <div class="pl-1 text-left">
                            <Typography variant="caption" color="error">
                              {errors.image}
                            </Typography>
                          </div>
                        )}
                      </>
                    )} */}

                        {/* {mongoId && ( */}
                        <>
                          <input
                            class="form-control"
                            type="file"
                            required=""
                            accept=".svga, .gift"
                            onChange={handleInputImage}
                          />
                          {errors.image && (
                            <div className="ml-2 mt-1">
                              {errors.image && (
                                <div className="pl-1 text__left">
                                  <span className="text-red">
                                    {errors.image}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                          {imagePath && (
                            <Fragment>
                              {!isSvga ? (
                                <img
                                  src={imagePath}
                                  class="mt-3 rounded float-left mb-2"
                                  height="100px"
                                  width="100px"
                                />
                              ) : (
                                <>
                                  <div
                                    id="svga"
                                    attr={mongoId}
                                    style={{
                                      boxShadow:
                                        "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                      float: "left",
                                      objectFit: "contain",
                                      marginRight: 15,
                                      width: "350px",
                                      height: "350px",
                                    }}
                                  ></div>
                                </>
                              )}

                              <div
                                class="img-container"
                                style={{
                                  display: "inline",
                                  position: "relative",
                                  float: "left",
                                }}
                              >
                                <i
                                  class="fas fa-times-circle material-icons remove_img text-danger"
                                  style={{
                                    position: "absolute",
                                    right: "-6px",
                                    top: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={removeImage}
                                ></i>
                              </div>
                            </Fragment>
                          )}
                        </>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                  <div className="row  formFooter">
                    <div className="col-12 text-end m0">
                      <Button
                        className={`bg-gray text-light`}
                        text={`Cancel`}
                        type={`button`}
                        onClick={() => dispatch(closeDialog())}
                      />
                      <Button
                        type={`submit`}
                        className={` text-white m10-left`}
                        style={{
                          backgroundColor: `${
                            isSubmit === true ? "#1ebc1e" : "#a1ffa1"
                          }`,
                        }}
                        text={`Submit`}
                        disabled={isSubmit === true ? false : true}
                        onClick={handleSubmit}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SvgaDialogue;
