import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "../../redux/slice/dialogueSlice";
import EmojiDialogue from "../table/emojis/EmojiDialogue";
import { BASE_URL } from "../utils/config";
import noImage from "../../assets/images/noImage.jpg";
import { deletegift, getAllGift } from "../../redux/slice/giftSlice";
import SvgaDialogue from "../table/Svga/SvgaDialogue";
import SVGA from "svgaplayerweb";

const Gift = () => {
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  const { gift } = useSelector((state) => state.gift);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getAllGift());
  }, []);

  useEffect(() => {
    setData(gift);
  }, [gift]);

  const handleOpenSVGADialogue = () => {
    dispatch(openDialog({ type: "svga" }));
  };
  const handleOpenGiftDialogue = () => {
    dispatch(openDialog({ type: "gift" }));
  };

  const handleEdit = () => {};

  const handleDelete = (id) => {
    dispatch(deletegift(id));
  };

  // useEffect(() => {
  //   if (data) {
  //     data.map((data) => {
  //       data?.gift?.map((gift, index) => {
  //         if (gift?.image?.split(".").pop() === "svga") {
  //           const player = new SVGA.Player(`div[attr="${index}"]`);
  //           const parser = new SVGA.Parser(`div[attr="${index}"]`);
  //           parser.load(BASE_URL + gift?.image, function (videoItem) {
  //             if (videoItem) {
  //               player.setVideoItem(videoItem);
  //               player.startAnimation();
  //             } else {
  //               console.error(
  //                 "Error loading SVGA file:",
  //                 BASE_URL + gift?.image
  //               );
  //             }
  //           });
  //         }
  //       });
  //     });
  //   }
  // }, [data]);
  return (
    <div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 d-flex justify-content-start">
          <div class="dropdown">
            <button
              className="btn waves-effect waves-light btn-danger btn-sm float-left dropdown-toggle py-2 px-3"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ fontSize: "20px" }}
            >
              New
            </button>
            <ul
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton1"
              style={{ backgroundColor: "#fff", marginTop: "10px" }}
            >
              <li style={{ cursor: "pointer" }}>
                <a className="dropdown-item" onClick={handleOpenSVGADialogue}>
                  SVGA
                </a>
              </li>
              <li style={{ cursor: "pointer" }}>
                <a className="dropdown-item" onClick={handleOpenGiftDialogue}>
                  Image,GIF
                </a>
              </li>
            </ul>
          </div>
          {/* <button
              type="button"
              className="btn waves-effect waves-light btn-danger btn-sm float-left"
              id="bannerDialog"
            >
              <i className="fa fa-plus"></i>
              <span className="icon_margin">New</span>
            </button> */}
        </div>
      </div>
      <div class="row">
        {data?.length > 0 ? (
          data?.map((data, index) => {
            return (
              <>
                <h2 className="text-start my-3">{data?.name}</h2>
                {data?.gift?.map((item, index) => {
                  return (
                    <>
                      <div
                        class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3"
                        key={index}
                      >
                        <div class="card contact-card card-bg">
                          <div class="card-body p-1">
                            <div className="row p-1">
                              <div className="col-8 ">
                                {item.image.split(".").pop() !== "svga" ? (
                                  <img
                                    src={
                                      item?.image
                                        ? BASE_URL + item?.image
                                        : noImage
                                    }
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      boxSizing: "border-box",
                                      objectFit: "contain",
                                    }}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    // id={`thumbnail-${index}`}
                                    src={BASE_URL +item?.svgaImage}
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      objectFit: "contain",
                                    }}
                                    alt=""
                                  />
                                )}
                              </div>
                              <div
                                className="col-2 text-end"
                                style={{
                                  padding: 0,
                                  paddingLeft: 5,
                                }}
                              >
                                <div class="contact-card-info mt-2 mb-3 px-3 mb-5">
                                  <h6>{item?.category?.name}</h6>
                                </div>

                                <div className="px-3">
                                  <i
                                    class="fas fa-trash text-white p-2 bg-danger rounded-circle"
                                    style={{ marginRight: 10, fontSize: 25 }}
                                    onClick={() => handleDelete(item?._id)}
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            );
          })
        ) : (
          <tr>
            <td colSpan="6" align="center">
              Nothing to show!!
            </td>
          </tr>
        )}
      </div>
      {dialogueType === "gift" && <EmojiDialogue />}
      {dialogueType === "svga" && <SvgaDialogue />}
    </div>
  );
};

export default Gift;
