import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../component/api/axiosApi";
import { Success } from "../../component/api/toastServices";

const initialState = {
  isLoading: false,
  isSkeleton: false,
  category: [],
};

export const getAllCategory = createAsyncThunk(
  "giftCategory/getAll",
  async () => {
    return apiInstance.get("giftCategory/getAll");
  }
);

export const AddCategory = createAsyncThunk(
  "giftCategory/create",
  async (payload) => {
    return apiInstance.post("giftCategory/create", payload);
  }
);

export const updateCategory = createAsyncThunk(
  "giftCategory/update",
  async (payload) => {
    return apiInstance.patch(
      `giftCategory/update?categoryId=${payload?.categoryId}`,
      payload?.formData
    );
  }
);

export const deleteCategory = createAsyncThunk(
  "giftCategory/delete",
  async (id) => {
    return apiInstance.delete(`giftCategory/delete?categoryId=${id}`);
  }
);

const categorySlice = createSlice({
  name: "categorySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCategory.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getAllCategory.fulfilled, (state, action) => {
      state.category = action.payload.category;
      state.isSkeleton = false;
    });

    builder.addCase(getAllCategory.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(AddCategory.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(AddCategory.fulfilled, (state, action) => {
      if (action.payload.status) {
        state?.category?.unshift(action?.payload?.category);

        Success("category Add Successfully");
      }
      state.isLoading = false;
    });

    builder.addCase(AddCategory.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateCategory.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateCategory.fulfilled, (state, action) => {
      if (action.payload.status) {
        const categoryInx = state.category.findIndex(
          (category) => category._id === action.payload.category._id
        );
        if (categoryInx !== -1) {
          state.category[categoryInx] = {
            ...state.category[categoryInx],
            ...action.payload.category,
          };
        }
      }
      state.isLoading = false;
      Success("Category Update Successfully");
    });

    builder.addCase(updateCategory.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteCategory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCategory.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.category = state.category.filter(
          (category) => category._id !== action.meta.arg
        );

        Success("category Delete Successfully");
      }
      state.isLoading = false;
    });
    builder.addCase(deleteCategory.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default categorySlice.reducer;
