import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import AuthRoute from "./component/utils/authRoute";
import Login from "./component/pages/Login";
import Admin from "./component/pages/Admin";
import "../src/assets/scss/custom/custom.css";
import "../src/assets/scss/default/default.css";
import "../src/assets/scss/style/style.css";
import "../src/assets/scss/dateRange.css";

function App() {
  return (
    <>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />

          <Route path="/admin/*" element={<Admin />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
