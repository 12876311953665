import React, { useEffect, useState } from "react";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategory } from "../../../redux/slice/categorySlice";
import Button from "../../extras/Button";
import ReactDropzone from "react-dropzone";
import { creategift } from "../../../redux/slice/giftSlice";

const EmojiDialogue = () => {
  const dispatch = useDispatch();
  const { category } = useSelector((state) => state.category);

  const [categoryData, setCategoryData] = useState();
  const [images, setImages] = useState([]);
  const [errors, setError] = useState({
    image: "",
    category: "",
  });

  console.log("category", category);
  useEffect(() => {
    dispatch(getAllCategory());
  }, [dispatch]);

  const onPreviewDrop = (files) => {
    setError({ ...errors, image: "" });

    files.forEach((file, index) => {
      Object.assign(file, { preview: URL.createObjectURL(file) });
      setImages((prevImages) => prevImages.concat(file));
    });
  };

  const removeImage = (file) => {
    if (file.preview) {
      const image = images.filter((ele) => {
        return ele.preview !== file.preview;
      });
      setImages(image);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (images.length === 0 || !categoryData) {
      const errors = {};

      if (images.length === 0) errors.image = "Please select an Image!";

      if (category === "Select Category" || !category)
        errors.category = "Please select a Category!";

      return setError({ ...errors });
    } else {
      const formData = new FormData();
      ;
      formData.append("category", categoryData);

      for (let i = 0; i < images.length; i++) {
        formData.append("image", images[i]);
      }

      dispatch(creategift(formData));
      dispatch(closeDialog());
    }
  };

  return (
    <>
      <div className="dialog">
        <div class="w-100">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-md-8 col-11">
              <div class="mainDiaogBox">
                <div class="row justify-content-between align-items-center formHead">
                  <div className="col-8">
                    <h2 className="text-theme m0">Gift Dialogue</h2>
                  </div>
                  <div className="col-4">
                    <div
                      className="closeButton"
                      onClick={() => {
                        dispatch(closeDialog());
                      }}
                    >
                      <i className="ri-close-line"></i>
                    </div>
                  </div>
                </div>
                <form id="expertForm">
                  <div className="row align-items-start formBody">
                    <div className="col-12 ">
                      <div className="inputData text  flex-row justify-content-start text-start">
                        <label for="category" class="false undefined">
                          Select Category
                        </label>
                        <select
                          class="form-select form-control"
                          aria-label="Default select example"
                          value={categoryData}
                          onChange={(e) => {
                            setCategoryData(e.target.value);
                            if (e.target.value === "Select Category") {
                              return setError({
                                ...errors,
                                category: "Please select a Category!",
                              });
                            } else {
                              return setError({
                                ...errors,
                                category: "",
                              });
                            }
                          }}
                        >
                          <option value="Select Category" selected>
                            Select Category
                          </option>
                          {category &&
                            category?.map((category) => {
                              return (
                                <option value={category?._id}>
                                  {category?.name}
                                </option>
                              );
                            })}
                        </select>
                        {errors.category && (
                          <div className="ml-2 mt-1">
                            {errors.category && (
                              <div className="pl-1 text__left">
                                <span className="text-red">
                                  {errors.category}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-2">
                        <label
                          className="form-control-label"
                          for="input-username"
                        >
                          Select (Multiple) Image or GIF
                        </label>

                        <>
                          <ReactDropzone
                            onDrop={(acceptedFiles) =>
                              onPreviewDrop(acceptedFiles)
                            }
                            accept="image/*"
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <div
                                    style={{
                                      height: 130,
                                      width: 130,
                                      border: "2px dashed gray",
                                      textAlign: "center",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <i
                                      className="fas fa-plus"
                                      style={{ paddingTop: 30, fontSize: 70 }}
                                    ></i>
                                  </div>
                                </div>
                              </section>
                            )}
                          </ReactDropzone>

                          {errors.image && (
                            <div className="ml-2 mt-1">
                              {errors.image && (
                                <div className="pl-1 text__left">
                                  <span className="text-red">
                                    {errors.image}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      </div>
                      <div className="col-12">
                        <div className="d-flex">
                          {images.length > 0 && (
                            <>
                              {images.length > 0 && (
                                <>
                                  {images?.map((file, index) => {
                                    return (
                                      file.type?.split("image")[0] === "" && (
                                        <>
                                          <img
                                            height="100px"
                                            width="100px"
                                            alt="app"
                                            src={file.preview}
                                            style={{
                                              boxShadow:
                                                "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                              border: "2px solid #fff",
                                              borderRadius: 10,
                                              marginTop: 10,
                                              float: "left",
                                              objectFit: "contain",
                                              marginRight: 15,
                                            }}
                                          />
                                          <div
                                            className="img-container"
                                            style={{
                                              display: "inline",
                                              position: "relative",
                                              float: "left",
                                            }}
                                          >
                                            <i
                                              className="fas fa-times-circle text-danger"
                                              style={{
                                                position: "absolute",
                                                right: "10px",
                                                top: "4px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => removeImage(file)}
                                            ></i>
                                          </div>
                                        </>
                                      )
                                    );
                                  })}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row  formFooter">
                    <div className="col-12 text-end m0">
                      <Button
                        className={`bg-gray text-light`}
                        text={`Cancel`}
                        type={`button`}
                        onClick={() => dispatch(closeDialog())}
                      />
                      <Button
                        type={`submit`}
                        className={` text-white m10-left`}
                        style={{ backgroundColor: "#1ebc1e" }}
                        text={`Submit`}
                        onClick={handleSubmit}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmojiDialogue;
